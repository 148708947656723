// Enter variables here

// Font variables
$base-fontsize: 16px;

// Colors variables

$color-white: #ffffff;
$color-black: #333333;
$color-dark: #131211;

$gray-1: #333;
$gray-2: #4f4f4f;
$gray-3: #828282;
$gray-4: #bdbdbd;
$gray-5: #E4E4E4;

$color-brand: #D61117;
$iu-1: #FEFBF8;
$iu-2: #FBFBFB;
$iu-3: #FAFAFA;
$iu-g-1: #7d7d7d;

$green: #27AE60;

// Typeface variables
$font1: 'Lato', sans-serif;

// Transition variables
$transition: .3s cubic-bezier(0.95, 0.05, 0.795, 0.035);