.carousel-image-item {
  // background: url(../../img/slide-1.jpg) no-repeat center center;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;

  img {
    max-height: 500px;
    max-width: 100%;
    object-fit: cover !important;
  }

  // @include breakpoint(md) {
  //   height: 479px;
  // }
}

.carousel-caption {
  top: 0;
  text-align: left;
  right: 8%;
  left: 8%;

  @include breakpoint(md) {
    top: 50%;
    transform: translateY(-50%);
  }

  h1,
  p {
    color: $color-white;
  }
}

.carousel-indicators {
  li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    border-radius: 10px;
    text-indent: -999px;
    cursor: pointer;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
}

.carousel-image-item-revamp {
  // background: url(../../img/slide-1.jpg) no-repeat center center;
  width: 100%;
  height: 100%;
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-size: cover;
  overflow: hidden;

  img {
    display: block;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain !important;
    background-position: center;
  }

  // @include breakpoint(md) {
  //   height: 479px;
  // }
}

/********************************/
/*          Hero Headers        */
/********************************/
.homeLanding {
  min-height: 90vh;
}

.carousel {
  background-image: url(/img/banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  height: 55vh;
  
  .hero {
    button:first-of-type {
      margin-right: 20px;
    }
    .dl-buttons-container {
      margin-top: 20px;
      display: flex;
      gap: 20px;
    }
  }

  @media (max-width: 1280px) and (max-height: 720px),
    (max-device-width: 1023px) {
    height: 50vh;

    .hero {
      button:first-of-type {
        margin-right: 10px;
      }
      .dl-buttons-container {
        margin-top: 0;
        display: flex;
        gap: 10px;
      }
    }
  }
}

.download-img {
  height: 50px;
}

.hero {
  position: absolute;
  bottom: 30px;
  left: 5%;
  z-index: 3;
  color: #fff;
  text-align: left;
  width: 60%;
}
.hero h1 {
  color: #fff;
  font-size: 3em;
  font-weight: bold;
  margin: 0;
  padding: 0;
  max-width: 60%;

  @media (max-width: 1280px) and (max-height: 720px) {
    font-size: 2em;
  }
}

.hero button {
  @media (max-width: 1280px) and (max-height: 720px) {
    font-size: 14px;
  }
}

.fade-carousel .carousel-inner .item .hero {
  opacity: 0;
  -webkit-transition: 2s all ease-in-out 0.1s;
  -moz-transition: 2s all ease-in-out 0.1s;
  -ms-transition: 2s all ease-in-out 0.1s;
  -o-transition: 2s all ease-in-out 0.1s;
  transition: 2s all ease-in-out 0.1s;
}
.fade-carousel .carousel-inner .item.active .hero {
  opacity: 1;
  -webkit-transition: 2s all ease-in-out 0.1s;
  -moz-transition: 2s all ease-in-out 0.1s;
  -ms-transition: 2s all ease-in-out 0.1s;
  -o-transition: 2s all ease-in-out 0.1s;
  transition: 2s all ease-in-out 0.1s;
}

.carousel-inner {
  height: 100%;
}

/********************************/
/*            Overlay           */
/********************************/
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(
    120deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 60%
  );
  opacity: 0.7;
}

@media (max-width: 770px) {
  .hero h1 {
    font-size: 2em;
    max-width: 60%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .download-img {
    height: 40px;
  }
}

@media only screen and (max-device-width: 1023px) {
  .homeLanding {
    min-height: auto;
  }
}

@media only screen and (max-device-width: 767px) {
  .download-img {
    height: 30px;
  }
  .hero {
    width: 100%;
  }
  .hero h1 {
    font-size: 2em;
    max-width: 100%;
  }
  .hero button {
    font-size: 12px;
  }
  .overlay {
    background: linear-gradient(
      120deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.4) 60%
    );
  }
  .carousel {
    height: 350px;
  }
}

@media only screen and (max-device-width: 576px) {
  .carousel {
    background-position: -20px center;
    height: 250px;

    .hero {
      left: 10px;
      width: auto;

      button:first-of-type {
        margin-right: 10px;
      }
      .dl-buttons-container {
        margin-top: 0px;
        gap: 10px;
      }
    }
  }
}
