.featured-section {
  padding: 0;
}

.feature-item {
  height: 220px;
  padding: 0px 15px;

  &:not(:last-of-type) {
    margin-bottom: 30px;
  }

  @include breakpoint(md) {
    height: 194px;

    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }

  a {
    background-image: url('../img/feature-1.jpg');
    background-repeat: no-repeat ; 
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    border-radius: 8px;
    overflow: hidden;
  }

  .title-bar {
    background: $gray-1;
    opacity: 0.8;
    color: $color-white;
    font-size: 1rem;
    width: 100%;
    padding: 7px 13px;
    display: flex;
    justify-content: space-between;
  }
}

.tmpl-newsletter,
.tmpl-thanks {

    .featured-section {
        display: none;
    }
}