.sidebar-menu {
  background: $color-brand;
  border-radius: 8px;

  @include breakpoint(lg) {
    margin-bottom: 30px;
  }

  .sidebar-menu-collapsible {
    .menu-head {
      .fas {
        font-size: pxToRem(14px);
      }
    }

    .links-container {
      li {
        &:first-of-type {
          border-top: 1px solid #AF090E;
        }
      }
    }
    
  }
  
  ul {
      list-style: none;
      padding: 0px;
      margin-bottom: 0;
      
    li {
      border-bottom: 1px solid #AF090E;

      &.menu-head a {
        // color: #000;
        letter-spacing: 0.1em;
        // opacity: 0.5;
        text-transform: uppercase;
        font-size: 10px;
      }

      &:last-of-type {
        border-bottom: none;
      }

      a {
        color: $color-white;
        padding: 7px 20px;
        font-size: 14px;
        font-family: $font1;
        font-weight: 700;
        display: block;        
      }
    }
  }
} 

.side-anchor {
  list-style: none;
  padding: 0px;

  li {
    margin-bottom: 20px;

    a {
      color: $gray-3!important;

      &:hover {
        color: $color-brand!important;
      }
    }
  }
}

.side-anchor {
  width: 100%;
  z-index: 99;
  position: static;
  display: none;
  transition: none;

  @include breakpoint(lg) {
    display: block;
  }

  &.sticky {
    position: sticky;
    top: 0;
    padding-top: 20px;
  }
  
}