.form-group {
  margin-bottom: 26px;
}

.form-control {
  border-radius: 4px;
  font-family: $font1;
  font-weight: 400;
  border: 1px solid $gray-5;
  font-size: pxToRem(14px);

  &:focus {
    color: $color-black;
    border-color: $gray-2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba($color-black,.25);
  }
}

.form-control-lg {
  height: calc(3rem + 2px);
  padding: pxToRem(17px);
  font-size: $base-fontsize;
}

.input-group {
  .form-control.form-search{
    border-radius: 4px;
    background-color: $iu-3;
  }
}
