.btn {
  font-family: $font1;
  font-weight: 700;
  border: none;
}

.btn-brand, .btn-primary {
  background-color: $color-brand;
  color: $color-white!important;
  text-decoration: none!important;

  &:hover {
    background-color: darken($color-brand, 10%);
    color: $color-white;
    text-decoration: none;
  }
}

.btn-secondary {
  background-color: rgba($color: #000000, $alpha: 0.1);
  color: $color-white!important;
  text-decoration: none!important;
}

.btn-apple-appstore, 
.btn-google-playstore,
.btn-huawei-appgallery {
  width: 148px;
  height: 44px;
  background-size: cover;
  display: block;
  cursor: pointer;
  text-indent: -99999px;
  overflow: hidden;
  border: none;
}


.btn-apple-appstore {
  background-image: url('/img/app-store-download.png');
}

.btn-google-playstore {
  background-image: url('/img/google-play-download.png');
}

.btn-huawei-appgallery {
  background-image: url('/img/huawei-appgallery-download.png');
}

.btn-link {
  &:hover {
    color: inherit;
    text-decoration: none;
    opacity: .7;
  }
}

.btn-md {
  padding: pxToRem(9px) pxToRem(35px);
  font-size: pxToRem(16px);
  line-height: 1.5;
  border-radius: pxToRem(5px);
  font-weight: 400;
}

.btn-group-lg>.btn,
.btn-lg {
  padding: pxToRem(11px) pxToRem(55px);
  font-size: pxToRem(18px);
  line-height: 1.5;
  border-radius: pxToRem(3px);
}