footer {
  padding: 20px 30px 20px 30px;   
  font-size: pxToRem(14px);
  color: white !important;
  background-color: red;

  a {
    color: white !important;
  }

  .footer-social {
    a:not(:last-of-type) {
      margin-right: 10px;
    }

    i {
      font-size: 26px;
    }
  }

  .footer-links {
    a:not(:first-of-type) {
      margin-left: 20px;
    }
  }
  
}

.tmpl-thanks {
  footer {
    display: none;
  }
}