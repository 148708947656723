.faq-option {

  margin-bottom: 20px;

  a {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 0 16px rgba(0,0,0,.05);
    border-radius: 8px;
    text-align: center;
    padding: 20px;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $gray-2;

    &:hover {
      box-shadow: 0 10px 15px rgba(0,0,0,.10);
    }

    img {
      margin-bottom: 10px;
      align-self: center;
    }
  }
}

.faq-wrap {
  margin-bottom: 100px;
  clear: both;

  h1 {
    margin-bottom: 40px;
  }

  .faq-item a {
    font-size: pxToRem(21px);
    font-weight: normal;
    color: $gray-2;
  }

  

  
}

.faq-item {
  margin-bottom: 20px;

  a {
    color: $gray-2;
    font-size: pxToRem(21px);
    font-weight: normal;
    border-bottom: 1px solid rgba(214,17,23,0.2);
    padding-bottom: 15px;

    i {
      font-size: pxToRem(18px);
    }
  }

  .card {
    padding: 20px 0px;
    border: none;
    line-height: 160%;
  }
}