// @font-face {
//   font-family: 'Lato-Light';
//   src: url('/fonts/Lato/Lato-Light.ttf')  format('truetype'); /* Safari, Android, iOS */
//   font-weight: 300;
// }

// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400&display=swap');

p,
ul li,
ol li {
  font-size: $base-fontsize;
  font-family: $font1;
  line-height: 180%;
  color: $gray-2;

  @include breakpoint(md) {
    font-size: $base-fontsize;
  }
}

.display-1,
.display-2,
.display-3 {
  font-family: $font1;
  font-weight: 300;
}

.display-4 {
  font-family: $font1;
  font-weight: 700;
  font-size: pxToRem(48px);
}

h1, .h1 {
  font-family: $font1;
  font-weight: 700;
  font-size: pxToRem(36px);
  color: $gray-1;
}

h2 {
  font-size: pxToRem(26px);
  font-weight: 700;
  line-height: 150%;
  color: $gray-1;
}

h3 {
  font-size: pxToRem(21px);
  font-weight: 700;
  line-height: 150%;
  color: $gray-1;
}

h4 {
  font-size: pxToRem(16px);
  font-weight: 700;
  line-height: 150%;
  color: $gray-1;
}

h5 {
  font-size: pxToRem(14px);
  font-weight: 700;
  line-height: 150%;
  color: $gray-1;
}

h6 {
  font-size: pxToRem(10px);
  font-weight: 700;
  line-height: 150%;
  color: $gray-1;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.section-heading {
  margin-bottom: 50px;

  @include breakpoint(md) {
    margin-bottom: 120px;
  }

  &::before {
    content: "";
    background-color: $color-brand;
    width: 2px;
    height: 60px;
    display: inline-block;
    margin-right: 20px;
    position: relative;
    bottom: -2px;

    @include breakpoint(md) {
      height: 100px;
    }
  }
}

.subheader1 {
  font-family: $font1;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 210%;
  font-size: pxToRem(14px);

  @include breakpoint(md) {
    letter-spacing: 7px;
    font-size: pxToRem(16px);
  }
}

.subheader2 {
  font-family: $font1;
  font-weight: 400;
  font-size: pxToRem(16px);
  text-transform: uppercase;
  letter-spacing: 7px;
  line-height: 200%;
}

.title1 {
  font-family: $font1;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 6px;
  line-height: 210%;
  color: $color-brand;
}

.title2 {
  font-family: $font1;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 180%;
  color: $color-brand;
  font-size: pxToRem(18px);
}

.caption1 {
  text-transform: uppercase;
  font-size: pxToRem(12px);
  letter-spacing: 3px;
  font-family: $font1;
  font-weight: 300;
  color: rgba($color-black, .5);
}

.caption2 {
  text-transform: uppercase;
  font-size: pxToRem(14px);
  letter-spacing: 2px;
  font-family: $font1;
  font-weight: 300;
}

.vision-text {
  font-family: $font1;
  font-weight: 300;
  font-size: pxToRem(28px);
  line-height: 160%;

  @include breakpoint(md) {
    font-size: pxToRem(36px);
    line-height: 170%;
  }
}

.lead {
  font-size: pxToRem(21px);
  font-family: $font1;
  font-weight: 400;
}

.lead2 {
  font-size: pxToRem(18px);
  font-family: $font1;
  font-weight: 400;
}