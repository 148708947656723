.banner-section {
  border-radius: 8px;
  color: $color-white;
  margin-bottom: 30px;
  min-height: 428px;
  display: flex;
  align-items: center;
  background-color: $color-black;
  background-repeat: no-repeat ; 
  background-position: left center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  h1, p, a {
    color: $color-white;
  }

  h1, p {
    margin-bottom: 1rem;
    text-shadow: 0 2px 8px rgba(#000, .09);
  }
}

.banner-section-content {
  padding: 0 30px;

  @include breakpoint(md) {
    padding: 0 90px;
    max-width: 75%;
  }

  .btn {
    margin-right: 10px;
  }
}

.banner-section-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @include breakpoint(md) {
    justify-content: unset;
  }

  a {
    margin-top: 8px;
  }
}