.search-wrap {

  .search-field {
    height: 60px;
    border-radius: 5px;
    border: 1px solid $gray-4;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.05);
    
    > div {
      height: 100%;
    }
    span {
      background: $color-white;
      border: none;
      padding: 20px 0px 20px 20px;
    }

    input[type=text] {
      height: 100%;
      border: none;
      color: $gray-1;

      &:focus {
        
        border-color: $color-white;
        outline: 0;
        box-shadow: none;
      }
    }
  }

  .toggle-display{
    margin-left: 20px;

    .toggle-label{
      font-size: 16px;
      font-weight: bold;
    }

    .switch {
      position: relative;
      margin-top:7px;
      margin-left: 10px;
      display: inline-block;
      width: 28px;
      height: 16px;

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
          background-color: $green;
        }

        &:focus + .slider {
          box-shadow: 0 0 1px $green;
        }

        &:checked + .slider:before {
          -webkit-transform: translateX(10px);
          -ms-transform: translateX(10px);
          transform: translateX(10px);
        }

      }

      .slider {
        position: absolute;
        border-radius: 16px;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $gray-4;
        -webkit-transition: .4s;
        transition: .4s;

        &:before {
          position: absolute;
          content: "";
          height: 10px;
          width: 10px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
          border-radius: 50%;
        }
      }
    }
  }
}



.branches-wrap {
  margin-bottom: 100px;

  h1 {
    margin-bottom: 40px;
  }

  .region-item {
    margin-bottom: 20px;

    a {
      color: $gray-1;
      font-size: pxToRem(26px);
      font-weight: bold;
      border-bottom: 1px solid rgba(214,17,23,0.2);
      padding-bottom: 15px;

      i {
        font-size: pxToRem(18px);
      }
    }

    .card {
      padding: 20px 0px;
      border: none;
      line-height: 160%;
    }

    .branch-item {
      margin-top: 35px;
      // padding: 20px 25px;
      // border: 1px solid #eaeaea;
      // border-radius: 5px;
      // box-shadow: 0px 0px 15px rgba(0,0,0,0.05);

      .branch-head {

        h2 {
          font-size: pxToRem(16px);
          color: $gray-1;
        }

        .badge {
          background: $green;
          padding: 5px 10px;
          color: $color-white;
          border-radius: 50px;
          font-size: pxToRem(10px);
          height: 100%;
          font-weight: bold;


        }
      }
      
      .branch-location {
        margin-bottom: 10px;

        i {
          color: $gray-4;
        }

        span {
          color: $gray-2;
          font-size: pxToRem(14px);
          margin-left: 5px;
        }
      }

      .branch-hours {

        span {
          font-size: pxToRem(14px);
          color: $gray-2;
        }

        i {
          color: $gray-4;
        }
      }
    }
  }
}

.info-branch-item{
  .info-branch-head {

    h2 {
      font-size: pxToRem(16px);
      color: $gray-1;
    }

    .info-badge {
      // background: $green;
      // padding: 5px 10px;
      color: $green;
      // border-radius: 50px;
      font-size: pxToRem(10px);
      height: 100%;
      font-weight: bold;


    }
  }
  
  .info-branch-location {
    margin-bottom: 10px;

    i {
      color: $gray-4;
    }

    span {
      color: $gray-2;
      font-size: pxToRem(14px);
      margin-left: 5px;
    }
  }

  .info-branch-hours {

    span {
      font-size: pxToRem(14px);
      color: $gray-2;
    }

    i {
      color: $gray-4;
    }
  }
  
}
#info-branch-head h2{
  /*color: rgb(255,0,0);*/
}