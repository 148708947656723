* {
  transition: all 200ms ease-in-out;
}

:root {
  font-size: $base-fontsize;
}

*:focus {
  outline: none !important;
}

html {
  background: #fff;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font1;
  background: #fff;
  color: $gray-2;
}

section {
  padding: 50px 0;

  @include breakpoint(md) {
    padding: 140px 0;
  }
}

p {
  max-width: 100%;
}

.material-icons {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

a {
  color: $gray-2;

  &:hover {
    color: $color-brand;
    text-decoration: none;
  }
}

.link1 {
  font-size: pxToRem(14px);
  color: $color-brand;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: $font1;
}

.caption {
  font-size: pxToRem(11px);
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

main {
  margin-top: 20px;
}

h1 {
  font-weight: bold;
  font-size: pxToRem(36px);
  color: $gray-1;
}

.page-content {
  margin-bottom: 50px;
  
  h1 {
    font-size: pxToRem(26px);
    margin-top: 40px;
    margin-bottom: 40px;
  }

  h2 {
    margin-top: 32px;
    margin-bottom: 26px;
  }

  h3 {
    margin-top: 24px;
    margin-bottom: 8px;
  }

  h4{
    margin-top: 24px;
    margin-bottom: 8px;
  }

  h5, h6 {
    margin-top: 16px;
    margin-bottom: 8px;
  }
  
  p {
    font-size: 1rem;
    color: $gray-2;
    line-height: 180%;
    max-width: 100%!important;
  }

  a {
    color: $color-brand;
    text-decoration: underline;

    &:hover {
      color: $color-brand;
      opacity: 0.7;;
    }
  }

  // img {
  //   max-width: 100%;
  //   display: block;
  //   width: 100%;
  // }

  .faq-item {
    margin-top: 50px;

    > a {
      font-size: pxToRem(26px);
      font-weight: bold;
      color: $gray-1;
    }
  }


  .w-md-50 {
    @include breakpoint(md) {
      width: 50%;
    }
  }

  .float-left {
    padding: 0px 30px 10px 0px;
  }

  .float-right {
    padding: 0px 0px 10px 30px;
  }

}

.faq-page-content {
  a {
  text-decoration: none;
  }
}

@media (max-width: 575.98px) {
  .page-content .float-left,
  .page-content .float-right {
    padding: 0px;
    float: none!important;
  }

  .page-content, .float-left img,
  .page-content, .float-right img {
    max-width: 100%;
  }
}

.customer-numbers {
  white-space: pre-line;
  font-weight: 400;
  font-size: 21px;
}

.hide-section {
  display: none;
}

.full-width {
  max-width: 100%;
  width: 100%;
}

.half-width {
  max-width: 50%;
  width: 50%;
}

.centered {
  text-align: center;
}

