.coming-soon {
  align-items: center;
  justify-content: center;
  height: 400px;
  display: flex;
  flex-direction: column;
}

.img-slider {
  height: 428px;
  background-size: cover !important;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  align-items: center;

  .row {
    width: 100% !important;
    margin-left: 35px;

    h1 {
      font-size: pxToRem(48px);
      color: $color-white;
      font-weight: bold;

      span {
        color: $color-brand;
      }
    }

    p {
      color: $color-white;
      font-size: pxToRem(22px);
    }
  }
}

.services-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 200px;
  min-height: 45%;
}

.custom-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.service-card {
  flex-direction: row;
  margin: 20px;
  padding: 20px 5px;
  gap: 14px;
  box-sizing: border-box;
}

.service-texts {
  h4 {
    font-size: large;
    text-align: start;
  }
}

.ml-financial-card {
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(184, 1, 1, 1) 0%,
    rgba(238, 1, 1, 1) 100%
  );
  border-radius: 15px;
  width: 80%;

  .jerico-avatar-left img {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 300px;
    max-width: 100%;
  }

  .jerico-avatar-right img {
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 300px;
  }

  @media (max-width: 770px) {
    .jerico-avatar-left img {
      position: absolute;
      bottom: 0px;
      left: -31px;
      height: 30%;
      max-width: 100%;
    }

    .jerico-avatar-right img {
      position: absolute;
      bottom: 0px;
      max-width: 100%;
      right: -2px;
      height: 29%;
    }

    .ml-financial-card {
      width: 100%;
      border-radius: 0;
      height: 750px;
    }
  }
}

.section-content-container {
  display: flex;
  align-items: center;
  padding: 50px 100px;
  gap: 80px;

  a {
    padding: 10px 20px;
  }
}

.service-img {
  width: 60px;
}

.section-img {
  height: 350px;
  width: 55%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 5;
}

.text-btn-container {
  width: 45%;
}

.footer-content {
  padding: 100px 0;
}

@media (max-width: 1400px) {
  .ml-financial-card {
    width: 100%;
    border-radius: 0;
    height: 500px;
  }
  .services-container {
    padding: 0 100px;
  }
  .section-content-container {
    padding: 50px 80px;
    gap: 50px;
  }
}

@media (max-width: 1024px) {
  .service-card {
    margin: 5px 0;
    padding: 0;
  }
  .services-container {
    padding: 20px;
    gap: 20px;
  }
  .custom-container {
    gap: 20px;
  }
}

@media (max-width: 1280px) and (max-height: 720px) {
  .service-card {
    margin: 10px 20px;
    padding: 10px 5px;
  }
  .custom-container {
    gap: 0;
  }
  .services-container {
    padding: 0 100px;
  }
}

@media only screen and (max-device-width: 1023px) {
  .services-container {
    padding: 20px 30px;
  }
  .text-btn-container {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  .section-img {
    width: 80%;
  }
  .section-content-container {
    padding: 50px;
    gap: 25px;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 25px;
      text-align: center;
    }

    a {
      padding: 8px 15px;
      font-size: 14px;
      margin: 0 auto;
    }
  }
  .footer-content {
    padding: 50px 20px;
  }
}

@media only screen and (max-device-width: 767px) {
  .ml-financial-card {
    width: 100%;
    border-radius: 0;
    height: 700px;
  }
  .service-img {
    height: 40px;
  }
  .services-container {
    padding: 20px 25px;
  }
  .service-texts {
    h4 {
      font-size: 16px;
    }
    div a {
      font-size: 14px;
    }
  }
  .fa-arrow-right {
    font-size: 12px;
  }
  .section-content-container {
    flex-direction: column;
  }
  .section-img {
    background-size: contain;
    height: 300px;
    width: 100%;
  }
  .text-btn-container {
    width: 100%;
  }
}

@media only screen and (max-device-width: 576px) {
  .service-img {
    height: 35px;
  }
  .custom-container {
    flex-direction: row;
    gap: 0;
  }
  .service-card {
    margin: 10px 0;
  }
  .services-container {
    padding: 30px 10px;
    gap: 0;
  }
  .section-content-container {
    padding: 25px;

    h1 {
      font-size: 20px;
    }
    a {
      font-size: 12px;
    }
  }
  .section-img {
    background-size: contain;
    height: 200px;
  }
  .footer-content {
    padding: 45px 20px 25px 20px;
  }
}
