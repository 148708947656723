header {
  border: none;
}

// .product-dropdown-menu {
//   @media (max-width: 1280px) and (max-height: 720px) {
//     left: -100px;
//   }
// }

.flag-text {
  display: flex;
  align-items: center;
  font-size: pxToRem(20px);
  color: $gray-3 !important;

  img {
    height: 28px;
    margin-right: 8px;
  }

  @media (max-width: 1280px) and (max-height: 720px) {
    img {
      height: 20px;
      margin-right: 4px;
    }
  }
}

.flag-dropdown {
  margin-right: 20px;

  @media (max-width: 1280px) and (max-height: 720px) {
    margin-right: 4px;
  }
}

.navSearch {
  @media (max-width: 1280px) and (max-height: 720px) {
    width: 140px !important;
  }
}

.navbar {
  padding: 0 1rem;

  .navbar-container {
    padding: 0px;
  }

  .navbar-brand {
    padding: 0px 0px 3px 0px;

    img {
      // max-width: 230px;
      height: 38px;

      @media (max-width: 1280px) and (max-height: 720px) {
        height: 28px;
      }

      @include breakpoint(xs) {
        height: 28px;
      }
    }
  }

  .nav-link {
    font-size: pxToRem(14px);
    color: $gray-3;
    cursor: pointer;

    &:hover,
    &.active {
      color: $color-brand;
    }
  }

  .nav-item {
    margin-right: 20px;

    @media (min-width: 1920px) {
      margin-right: 26px;
    }

    @media (max-width: 1280px) and (max-height: 720px), (max-width: 1280px) {
      margin-right: 4px;
    }

    &.active a {
      color: $color-brand;
    }
  }

  .nav-item-base {
    margin-right: 4px;
  }

  .nav-link-parent {
    color: $gray-1;
  }

  .navbar-toggler {
    border: transparent;
    padding: 0.75rem;
    color: #7f7f7f;
  }

  .navbar-toggler-icon {
    opacity: 0.5;
    height: 1em;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHMAAABkCAMAAACCTv/3AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAZQTFRFAAAA5ubmSUUG+gAAAAJ0Uk5T/wDltzBKAAAAPklEQVR42uzYQQ0AAAgDseHfNC4IyVoD912WAACUm3uampqampqamq+aAAD+IVtTU1NTU1NT0z8EAFBsBRgAX+kR+Qam138AAAAASUVORK5CYII=);
  }
}

.nav-item {
  .dropdown-toggle {
    &:after {
      content: "\f078";
      font-family: "FontAwesome";
      border: none;
      font-size: 10px;
    }
  }

  .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin: 0;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 975px;

    .row {
      margin-right: 0px;
      margin-left: 0px;
    }

    .featured-nav {
      display: flex;

      img {
        margin-right: 15px;
      }

      h4 {
        color: $color-brand;
        font-weight: bold;
      }
      p {
        color: $gray-3;
        line-height: 160%;
        font-size: 14px;
      }

      &:hover h4 {
        color: $gray-2;
      }
    }

    .dropdown-item {
      font-size: pxToRem(14px);

      &:hover {
        color: $color-brand;
      }
    }

    .secondary-nav {
      justify-content: space-between;

      a {
        font-weight: bold;
        color: $color-brand;

        &:hover {
          color: $gray-2;
        }
      }
    }
  }

  .dropdown-menu-revamp {
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin: 0;
    box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    padding-bottom: 0;
    width: 350px;
    max-height: 80vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-brand;
    }

    .secondary-nav {
      justify-content: space-between;
      display: flex;
      flex-direction: column;

      .products-services-title {
        padding: 8px 20px;
        padding-bottom: 18px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        color: $gray-3;
        line-height: 1.2;
      }

      a {
        font-weight: bold;
        color: $color-brand;
        padding: 8px 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);

        &:hover {
          color: $gray-2;
        }
      }
    }
  }
}

// .navbar-main {
//   // position: absolute;
//   width: 100%;

//   .navbar-brand {
//     padding: 0;

//     img {
//       width: 90px;
//     }
//   }

//   .nav-toggle {
//     color: $color-white;
//     font-family: $font1;
//     text-transform: uppercase;
//     letter-spacing: 1px;
//     font-size: pxToRem(14px);
//     line-height: 2rem;

//     i {
//       margin-left: 5px;
//     }

//     &:hover {
//       text-decoration: none;
//       position: relative;
//       top: -2px;
//     }
//   }

//   &.--scrolled {
//     position: fixed;
//     background-color: $color-white;
//     z-index: 2;
//     padding-top: .8rem;
//     padding-bottom: .8rem;
//     border-bottom: 1px solid rgba(#000, .06);

//     @include breakpoint(md) {
//       padding-top: 1rem;
//       padding-bottom: 1rem;
//     }

//     .navbar-brand {
//       img {
//         height: 40px;
//         width: auto;

//         @include breakpoint(md) {
//           height: 50px;
//         }
//       }

//     }

//     .nav-toggle {
//       color: rgba(#000, .2);
//     }
//   }
// }
