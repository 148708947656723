.featured {
    img {
        border-radius: 8px;
    }

    .grid-item {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            margin-top: 0px;
        }        
    }
}

.grid-view {

    div {
        margin-bottom: 30px;
    }
}

.grid-item {

    h2 {
        margin-top: 20px;
    }

    img {
        border-radius: 8px;
    }

    p {
        margin-top: 20px;
    }

    a {
        font-weight: bold;
        color: $color-brand;
        border-bottom: 1px solid $color-brand;
        padding-bottom: 3px;

        &:hover {
            opacity: 0.5;
        }
    }

    
}

.first-page {
    .grid-item {
        &:first-of-type {
            max-width: 100%;
            flex: 0 0 100%;
            display: flex;
    
            .grid-image {
                max-width: 50%;
            }
    
            .grid-details {
                margin-left: 40px;
                max-width: 50%;
            }

            .tag {
                display: inline-block;
            }
        }
    }
}

@media (max-width: 992px) { 

    .first-page {
        .grid-item:first-of-type {
            display: block;

            .grid-image {
                max-width: 100%;
            }

            .grid-details {
                max-width: 100%;
                margin-left: 0!important;
            }

            img {
                max-width: 100%;
            }
        }
    }
    
}


.sub-details {
    font-size: pxToRem(12px);
    letter-spacing: 1.5px;
    text-transform: uppercase;

    .tag {
        color: $color-brand;
        display: none;        
    }
}

.back-btn {
    color: $gray-3;
    opacity: 0.7;
    margin-top: 20px!important;
    display: block;

    i {
        margin-right: 10px;
    }

    &:hover {
        opacity: 1;
    }
}



